<template>
  <div class="alarmCont">
    <div class="listheader " >
      <div class="titleName">温度异常</div>
      <span>合计：{{offlineRoomList.length}}</span>
    </div>
    <div class="alarm_panel ">
      <!-- <div v-if="sos" class="listcount">
        <template v-if="offlineStores.length">
          <div
            v-for="(item,index) in offlineStores"
            :key="index"
            class="list "
            @click="offlineClick(item)"
          >
            <div class="hotelName">{{showName(item.hotelName)}}</div>
            <span class="Num">{{item.count}}</span>
          </div>
        </template>
        <template v-else>
          <div class="noData flex flex-center align-center">暂无 温度异常 数据</div>
        </template>
      </div> -->
      <div  class="storesDetails" >
        <div class="storesName" v-if="offlineName">{{showName(offlineName)}}</div>
        <div class="detailsListCont" >
          <!-- <img src="../../assets/loading.gif" alt="" v-if="showDataFlag"> -->
          <div v-if="!showDataFlag">
            <div v-for="(item,index) in offlineRoomList" :key="index" >
              <!-- 2551 -->
              {{item.floorName}}{{item.roomNumber}}
              房
            </div>
          </div>
          <div v-if="!offlineRoomList||offlineRoomList.length==0" class="noData2 flex flex-center align-center">暂无 温度异常 数据</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_Offlinesotres, GET_OfflinRoom ,GET_temperatureException_TYPE_1 } from "@/api";
import { showName } from "@/api/common";
export default {
  props:{
   roleType:{
      type:String,
      default:'0'
   }
  },
  data() {
    return {
      offlineStores: [], //sos门店
      sos: true,
      showDataFlag: false,
      offlineName: "佛山禅城酒店", //门店名
      offlineRoomList: [],
    };
  },
  computed: {
     offlineStoresTotal(){
       return this.offlineStores.reduce(function(sum, item){
        let val=item.count ?item.count: 0
            return sum +  val ;
          }, 0);
    }
  },
   created() {
    this.GET_Room_data();
    this.yiCInter = null;
    this.yiCInter = setInterval(() => {
      this.GET_Room_data();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.yiCInter);
  },
  methods: {
     showName(val){
     return showName(val)
    },
    async GET_Room_data(){
      // this.showDataFlag=true
      try {
        let res = await GET_temperatureException_TYPE_1()
        this.offlineName= res.data.data.hotelName
         this.roomList=res.data.data.roomList
         this.showDataFlag=false
      } catch (error) {
         this.showDataFlag=false
      }
    },


    //sos报警门店
    async getlist(data) {
      let res = await GET_Offlinesotres(data);

      this.offlineStores = res.data.data;
    },
    // 点击报警门店事件
    offlineClick(item) {
      this.sos = false;
      this.showDataFlag = true;
      this.offlineName = item.hotelName;
      this.getRoom(item);
    },
    //sos报警房间
    async getRoom(data={hotelId:16}) {
      let res = await GET_OfflinRoom(data);
      this.offlineRoomList = res.data.data;
      this.showDataFlag = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
@import "@/assets/mdKanban/listData.scss";

</style>